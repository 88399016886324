import { useEffect, useRef, useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import ReactMarkdown from 'react-markdown';
import Popup from 'reactjs-popup';

import ChatbotIcon from '#/assets/svgs/ChatbotIcon.svg';
import CrossIcon from '#/assets/svgs/CrossIcon.svg';
import OnlineStatus from '#/assets/svgs/OnlineStatus.svg';
import SendIcon from '#/assets/svgs/SendIcon.svg';
import { apiEndpoints } from '#/services';

function Chatbot() {
  const [message, setMessage] = useState('');
  const [streamedResponse, setStreamedResponse] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const accessToken = 'f*1sb*6-3t&sr0^42np$*(6#ojgyj&o5*^005o(9#5e8f)2*gm';
  const contentRef = useRef(null);

  // Scroll to bottom of chat when messages update
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [chatHistory, streamedResponse, isResponseLoading]);

  const { mutate, isPending } = useMutation({
    mutationFn: async payload => {
      // Set loading state to true before making the API call
      setIsResponseLoading(true);

      const response = await fetch(`${import.meta.env.VITE_BASE_URL}${apiEndpoints.MEDICAL_CHAT}`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json', Authorization: accessToken },
      });

      if (!response.body) throw new Error('No response body');

      // Clear any previous streaming response
      setStreamedResponse('');

      // Start reading the stream
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let receivedFirstChunk = false;
      let completedResponse = '';
      let responseSessionId = null;
      let finalResponse = null;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        // Split by newlines to handle multiple data events in a single chunk
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (!line.trim()) continue;

          if (line.startsWith('data:')) {
            const jsonString = line.replace('data:', '').trim();
            if (jsonString === '[DONE]') continue;

            try {
              const data = JSON.parse(jsonString);

              // Check if the data contains the complete response with session_id
              if (data.session_id) {
                responseSessionId = data.session_id;
                finalResponse = data.response || data.answer || completedResponse;
                continue;
              }

              if (data.content) {
                // Once we receive the first chunk, turn off the loading indicator
                if (!receivedFirstChunk) {
                  receivedFirstChunk = true;
                  setIsResponseLoading(false);
                }

                // Accumulate the complete response
                completedResponse += data.content;

                // Update the streaming response in real-time
                setStreamedResponse(prev => prev + data.content);
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          }
        }
      }

      // Make sure loading is turned off when stream is complete
      setIsResponseLoading(false);

      // If we received a session_id in the response, store it
      if (responseSessionId) {
        setSessionId(responseSessionId);
      }

      // Return the complete response and session ID
      return {
        response: finalResponse || completedResponse,
        sessionId: responseSessionId,
      };
    },
    onSuccess: data => {
      if (data.response) {
        // Add the complete assistant response to chat history
        const assistantMessage = {
          content: data.response,
          timestamp: dayjs().format('h:mm a'),
          isUser: false,
        };

        setChatHistory(prev => [...prev, assistantMessage]);

        // Store the session ID if it was returned
        if (data.sessionId) {
          setSessionId(data.sessionId);
        }
      }

      // Clear the streamed response and loading state
      setStreamedResponse('');
      setIsResponseLoading(false);
    },
    onError: error => {
      console.error('Error:', error);

      // Add an error message to the chat
      const errorMessage = {
        content: 'Sorry, there was an error processing your request.',
        timestamp: dayjs().format('h:mm a'),
        isUser: false,
      };

      setChatHistory(prev => [...prev, errorMessage]);
      setStreamedResponse('');
      setIsResponseLoading(false);
    },
  });

  const handleChange = e => setMessage(e.target.value);

  const sendMessage = async () => {
    if (!message.trim() || isPending) return;

    // Add user message to chat history immediately before API call
    const userMessage = {
      content: message.trim(),
      timestamp: dayjs().format('h:mm a'),
      isUser: true,
    };

    setChatHistory(prev => [...prev, userMessage]);

    // Set loading to true when sending message
    setIsResponseLoading(true);

    // Prepare the payload, including session_id if available
    const payload = {
      message: message.trim(),
      is_anonymous: true,
    };

    // Add the session_id to the payload if it exists
    if (sessionId) {
      payload.session_id = sessionId;
    }

    // Call the API with the prepared payload
    mutate(payload);

    // Clear input field immediately
    setMessage('');
  };

  const handleEnterKeyPress = async e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      await sendMessage();
    }
  };

  // Convert response for proper markdown rendering
  const prepareMarkdown = text => {
    if (!text) return '';

    // Ensure dash-based lists are converted to proper markdown lists
    const lines = text.split('\n');
    let formatted = '';

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      // If line starts with "- " (dash and space), ensure it's rendered as markdown list
      if (line.trim().startsWith('- ')) {
        formatted += line + '\n';
      }
      // Regular line
      else {
        formatted += line + '\n';
      }
    }

    return formatted;
  };

  const onChatbotPopupOpen = () => {
    setIsChatbotOpen(true);
  };

  const onChatbotPopupClose = () => {
    setIsChatbotOpen(false);
  };

  return (
    <Popup
      trigger={
        <button className="chatbot-btn">
          {isChatbotOpen ? (
            <CrossIcon />
          ) : (
            <span className="chatbot-icon">
              <img src="https://clma-prod-space.fra1.cdn.digitaloceanspaces.com/media/chatbot.gif" />
            </span>
          )}
        </button>
      }
      closeOnDocumentClick={false}
      position="top right"
      arrow={false}
      offsetY={8}
      open={isChatbotOpen}
      onOpen={onChatbotPopupOpen}
      onClose={onChatbotPopupClose}
    >
      <div className="header">
        <div className="avatar-detail">
          <span className="avatar">
            <ChatbotIcon />
          </span>
          <div className="detail">
            <h5>Doctor C</h5>
            <p>
              <OnlineStatus /> Available for your assistance
            </p>
          </div>
        </div>
      </div>
      <div className={`content ${chatHistory.length === 0 ? 'empty-content' : ''}`} ref={contentRef}>
        {chatHistory.length === 0 ? (
          <div>
            <ChatbotIcon />
            <h2>Hello there, How can I help you today?</h2>
            <p>I'm here to provide you with health advice and support.</p>
          </div>
        ) : (
          <>
            {/* Display chat history */}
            {chatHistory.map((msg, index) => (
              <div key={index} className={`message ${msg.isUser ? 'sender' : 'receiver'}`}>
                {msg.isUser ? (
                  <p>{msg.content}</p>
                ) : (
                  <div className="markdown-content">
                    <ReactMarkdown>{prepareMarkdown(msg.content)}</ReactMarkdown>
                  </div>
                )}
                <span>{msg.timestamp}</span>
              </div>
            ))}

            {/* Show loading indicator while waiting for response */}
            {isResponseLoading && (
              <div className="message receiver">
                <p>
                  <span className="loading-dots">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </span>
                </p>
                <span>{dayjs().format('h:mm a')}</span>
              </div>
            )}

            {/* Show streaming response if available */}
            {streamedResponse && (
              <div className="message receiver">
                <div className="markdown-content">
                  <ReactMarkdown>{prepareMarkdown(streamedResponse)}</ReactMarkdown>
                </div>
                <span>{dayjs().format('h:mm a')}</span>
              </div>
            )}
          </>
        )}
      </div>
      <div className="footer">
        <input
          type="text"
          value={message}
          placeholder="Ask anything"
          onChange={handleChange}
          onKeyDown={handleEnterKeyPress}
          disabled={isPending || isResponseLoading}
        />
        <button
          className={!!message && !isPending && !isResponseLoading ? 'active' : ''}
          onClick={sendMessage}
          disabled={!message.trim() || isPending || isResponseLoading}
        >
          <SendIcon />
        </button>
      </div>
    </Popup>
  );
}

export default Chatbot;
